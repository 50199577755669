import { createGlobalStyle } from 'styled-components';

//* mobile: up to 768px
//* tablet: from 769px
//* desktop: from 1024px
//* widescreen: from 1216px
//* fullhd: from 1408px

const GlobalStyles = createGlobalStyle`
  :root {
    //*Colors */
    --black: #1f1f1f;
    --yellow: #FECB47;
    --yellow-darken: #dca413;
    --yellow-lighten: #ffdf8e;
    --white:#FFFDFD;
    --white-grey: #F3F1F1;
    //*Viewport Widths */
    --mobile: 768px;
    --tablet: 769px;
    --desktop: 1024px;
    --widescreen: 1216px;
    --fullhd: 1408px;
    //*Margin */
    --margin: 4rem;
    //*Grid Gap */
    --row-gap: 4rem;
  @media (max-width:768px ){
    --margin: 2rem;
  }
  }

  html {
    font-size: 10px;
  }
  body {
    background: var(--white-grey);
    font-size: 2rem;

  }
  #___gatsby {
    max-width: 1440px;
    margin: 0 auto;
  }
  #gatsby-focus-wrapper {
    display: grid;
    grid-auto-rows: min-content;
    min-height: 100vh;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--yellow) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--yellow) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }
  .grow {
        display: inline-block;
      transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
      :hover {
        transform: scale(1.1);
        transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
        color: var(--yellow);
      }
    }
    .consent-container {
      color: var(--black);
      background-color: white;
      border-radius: 20px 20px 0 0;
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: auto;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: fixed;
      max-width: 75vw;
      z-index: 999;
    }
    .consent-content {
      flex: 1 0 300px;
      margin: 15px;
      font-weight: 300;
    }
    .consent-button {
      background-color: var(--yellow);
      color: var(--black);
      border-radius: 20px;
      font-family: Norican, Cursive;
      box-shadow: none;
      cursor: pointer;
      flex: 0 0 auto;
      padding: 5px 10px;
      margin: 15px;
      transition: transform 0.3s cubic-bezier(1,0.07,0,2.21);
      :hover {
        background-color: var(--yellow-lighten);
        transition: transform 0.3s cubic-bezier(1,0.07,0,2.21);
        transform: scale(1.1);
      }
      :focus, :active {
        outline: none;
      }
    }
`;

export default GlobalStyles;
