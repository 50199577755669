import React from 'react';
import { GrFacebook, GrInstagram, GrLinkedin, GrTwitter } from 'react-icons/gr';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  margin-top: var(--margin);
  margin-bottom: var(--margin);
  margin-left: var(--margin);
  margin-right: var(--margin);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: var(--row-gap);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .container {
    display: grid;
    align-items: flex-end;
  }
  .logo-container {
    grid-template-columns: auto auto auto 1fr;
    grid-template-rows: auto auto;
    column-gap: 1.8rem;
    .logo {
      user-select: none;
      grid-row: 2;
      grid-column: 1 / span 4;
    }
    .some-link {
      z-index: 1;
      :first-child {
        margin-left: 6px;
      }
      a {
        display: inline-block;
        font-size: 2.5rem;

        transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
        :hover {
          transform: scale(2);
          transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
          color: var(--yellow);
        }
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <h4>
          <span className="cursive">Soita tai Lähetä</span>{' '}
          <span className="bold">Sähköpostia.</span>
        </h4>
      </div>
      <div className="container">
        <h4>
          <span className="cursive">
            <a className="grow" href="tel:+358400630070">
              0400630070
            </a>
          </span>
          <br />
          <span className="bold">
            <a className="grow" href="mailto:yhteydenotto@digis.fi">
              yhteydenotto@digis.fi
            </a>
          </span>
        </h4>
      </div>
      <div className="container logo-container">
        <div className="some-link">
          <a
            href="https://www.facebook.com/DigitaalinenMarkkinointiDigis/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <GrFacebook />
          </a>
        </div>
        <div className="some-link">
          <a
            href="https://www.instagram.com/digis.fi/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <GrInstagram />
          </a>
        </div>
        <div className="some-link">
          <a
            href="https://www.linkedin.com/company/digitaalinen-markkinointi-digis"
            target="_blank"
            rel="noreferrer noopener"
          >
            <GrLinkedin />
          </a>
        </div>
        <div className="some-link">
          <a
            href="https://twitter.com/DigitaalinenD"
            target="_blank"
            rel="noreferrer noopener"
          >
            <GrTwitter />
          </a>
        </div>

        <span className="logo">DIGIS</span>
      </div>
    </FooterStyles>
  );
}
