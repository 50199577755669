import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

const NavigationStyles = styled.nav`
  display: flex;
  justify-content: flex-end;
  .top-bar {
    flex: 1;
    padding: 1rem 0 1rem var(--margin);
  }
  .rotate {
    display: inline-block;
    transition: all ease-in 0.25s;
    ${(props) => {
      if (props.isShown)
        return `
            transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
            transform: rotate(90deg);
    `;
    }}
  }
  .menu {
    font-size: 3.2rem;
    padding: 1rem var(--margin) 1rem;
    text-align: right;
    position: relative;
    cursor: pointer;
  }
  .dropdown {
    z-index: 3;
    position: absolute;
    right: var(--margin);
    top: calc((var(--margin)) * 1.7);
    @media (max-width: 768px) {
      top: calc((var(--margin)) * 4);
    }
    li {
      font-size: 2.2rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.2rem 2rem;
      a {
        white-space: nowrap;
        display: inline-block;
        :hover {
          transform: scale(1.1);
          transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
        }
      }
    }
  }
`;

export default function Navigation() {
  const [isShown, setIsShown] = useState(false);

  return (
    <NavigationStyles role="navigation" isShown={isShown}>
      <div className="top-bar" />
      <div
        className="menu"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <span className="cursive">
          Menu <span className="rotate">{'>'}</span>
        </span>
        {isShown && (
          <ul className="dropdown">
            <li>
              <Link to="/">Digis</Link>
            </li>
            <li>
              <Link to="/ely-kehittamispalvelut">ELY-kehittämispalvelut</Link>
            </li>
            <li>
              <Link to="/digimarkkinointi">Digimarkkinointi</Link>
            </li>
            <li>
              <Link to="/ota-yhteytta">Ota yhteyttä</Link>
            </li>
          </ul>
        )}
      </div>
    </NavigationStyles>
  );
}
