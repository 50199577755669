// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-digimarkkinointi-js": () => import("./../src/pages/digimarkkinointi.js" /* webpackChunkName: "component---src-pages-digimarkkinointi-js" */),
  "component---src-pages-ely-kehittamispalvelut-js": () => import("./../src/pages/ely-kehittamispalvelut.js" /* webpackChunkName: "component---src-pages-ely-kehittamispalvelut-js" */),
  "component---src-pages-ely-opas-js": () => import("./../src/pages/ely-opas.js" /* webpackChunkName: "component---src-pages-ely-opas-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-js": () => import("./../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../src/pages/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

