import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`
    html {
    color: var(--black);
    }
    body {
        font-family: 'Oswald', sans-serif;
    }
    /*clamp(minimum, preferred, maximum); */
    h1 {
        font-size: clamp(4rem, 12vw, 13rem);
        font-weight: 500;
        line-height: 1.5;
    }
    h2{
        font-size: clamp(2.5rem, 6vw, 4.5rem);
        line-height: 1.2;
        margin-top: calc((var(--margin)) * 1.5);
        margin-bottom: var(--margin);
        @media (max-width: 768px){
            &:not(:first-of-type) {
                margin-top: calc((var(--margin)) * 2);
            }   
        }

    }
    h3 {
        font-size: clamp(2rem, 10vw, 3rem);
        line-height: 1.2;
        margin-bottom: var(--margin);

    }
    h4 {
        font-size: clamp(1.4rem, 6vw, 2.6rem);
        line-height: 1.2;

    }
    p {
        font-size: clamp(1.8rem, 10vw, 2rem);
        font-weight: 300;
        line-height: 1.2;
        &:not(:last-of-type) {
            margin-bottom: var(--margin);
        }
            
    }
    .logo {
        font-size: clamp(7rem, 8vw, 11rem);
        font-weight: 700;
        line-height: 1;
        color: var(--yellow);
    }
    //* span classes */
    .cursive {
        font-family: 'Norican', cursive;
        color: var(--black);
    }
    .bold {
        font-weight: 700;
    }
    .info {
        margin-bottom: 0 !important;
        text-align: left;
        &:last-child{
            margin-top: var(--margin);
        }
    }
    .link {
            transition: color 0.15s ease-in-out;
        :hover {
            transition: color 0.2s ease-in-out;
            color: var(--yellow);
        }
    }
    button {
        font-size: 2.5rem;
    }
    ${'' /* a{
        :hover{
            color: var(--yellow);
        }
    } */}
    //* H2 span classes end */
`;

export default Typography;
