import React from 'react';
import CookieConsent from 'react-cookie-consent';

export default function CustomCookieConsent() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Hyväksyn"
      declineButtonText="En"
      cookieName="gatsby-gdpr-google-analytics"
      disableStyles
      buttonClasses="consent-button"
      containerClasses="consent-container"
      contentClasses="consent-content"
    >
      Hyväksytkö sivustomme evästeiden käytön kävijäseurantaan ja
      markkinointiin?
    </CookieConsent>
  );
}
