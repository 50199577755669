import React from 'react';
import { Helmet } from 'react-helmet';
import reset from 'reset-css-complete';

import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import CustomCookieConsent from './CustomCookieConsent';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Layout({ children }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'fi',
        }}
      />
      <GlobalStyles />
      <Typography />
      <CustomCookieConsent />
      <Navigation />
      {children}
      <Footer />
    </>
  );
}
